import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Section from "../Section";

import "./Section3.scss";

const LocationSection3 = ({ data }) => {
  const image = getImage(data.image);
  return (
    <Section className="tri-l jsTriL" anchor={data.anchor}>
      <div className="tri-l">
        <div className="tri-l__content">
          <div>
            <h2
              className="title"
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            <p>{data.description}</p>
          </div>
        </div>
        <div className="tri-l__images">
          <div className="zoom-out">
            <GatsbyImage image={image} alt="" className="jsTriLImg" />
          </div>
        </div>
      </div>
    </Section>
  );
};

LocationSection3.propTypes = {
  data: PropTypes.object,
};

export default LocationSection3;
