import React, { useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useData } from "../utils/DataProvider"
import {
  navAnimation,
  revealText,
  moveLines,
  imageReveal,
  coverReveal } from "../animations/Animate"
import LayoutInner from "../components/LayoutInner"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import LocalNav from "../components/LocalNav";
import ImageText from "../components/ImageText"
import LocationSection2 from "../components/Location/Section2"
import LocationSection3 from "../components/Location/Section3"
import LocationSection4 from "../components/Location/Section4"

gsap.registerPlugin(ScrollTrigger)

const LocationPage = ({ data, location }) => {
  const [state, setState] = useData()
  const locationData = data.locationQuery
  const sections = Object.values(locationData.sectionsQuery)

  useEffect(() => {
    setState({ ...state, color: locationData.meta.color})
  }, [setState, locationData])

  const section1Location = useCallback(() => {
    coverReveal()
    moveLines('380px')
    // DISABLED per client request
    // gsap.from('.location-s1 p', {
    //   duration: 1,
    //   opacity: 0,
    //   y: 40,
    //   ease: "power3.easeOut",
    //   scrollTrigger: {
    //     trigger: '.location-s1 p',
    //     start: "top 80%",
    //     toggleActions: 'play reverse play reverse',
    //   }
    // })
  })
  const section2Location = useCallback(() => {
    imageReveal('.jsTwoLImg1', 'top 80%')
    imageReveal('.jsTwoLImg2', 'top 80%')
    // DISABLED per client request
    // ScrollTrigger.batch('.two-l__item', {
    //   onEnter: batch => gsap.from(batch, {
    //     y: 40,
    //     autoAlpha: 0,
    //     stagger: 0.3,
    //     duration: 1,
    //     ease: "power3.easeOut",
    //   })
    // });
  })
  const section3Location = useCallback(() => {
    // DISABLED per client request
    // revealText('.tri-l__content .ui-block__text')
    // gsap.from('.tri-l__content p', {
    //   duration: 1,
    //   opacity: 0,
    //   y: 40,
    //   ease: "power3.easeOut",
    //   scrollTrigger: {
    //     trigger: '.tri-l__content',
    //     start: "top 80%",
    //     end: "bottom 30%",
    //     scrub: 1,
    //   }
    // })
    gsap.from('.jsTriLImg', {
      duration: 1,
      opacity: 0,
      delay: 1,
      scale: 1.4,
      ease: "power4.easeOut",
      scrollTrigger: {
        trigger: '.jsTriLImg',
        start: "top 80%"
      }
    })
  })

  const section4Location = useCallback(() => {
    // DISABLED per client request
    // revealText('.jsFourLTitle .ui-block__text')
    imageReveal('.jsFourLImg2', 'top 90%')
    imageReveal('.jsFourLImg1', 'top 80%')
    imageReveal('.jsFourLImg3', 'top 80%')
    // DISABLED per client request
    // gsap.from('.jsFourLRight p', {
    //   duration: 1,
    //   opacity: 0,
    //   y: 40,
    //   ease: "power3.easeOut",
    //   stagger: 0.3,
    //   scrollTrigger: {
    //     trigger: '.jsFourLRight',
    //     start: "top 100%",
    //     toggleActions: 'play reverse play reverse',
    //   }
    // })
  })

  const locationAnimation = useCallback(() => {
    navAnimation()
  })

  useEffect(() => {
    locationAnimation()
    section1Location()
    section2Location()
    section3Location()
    section4Location()
  }, [])
  return (
    <LayoutInner location={location}>
      <SEO title={locationData.meta.title} />
      <Hero data={locationData.heroQuery} />
      <LocalNav sections={sections} />
      <div id={locationData.sectionsQuery.section1.anchor}>
        <ImageText
          data={locationData.sectionsQuery.section1}
          order={"image,content"}
          anchor={false}
        />
        <LocationSection2 data={locationData.sectionsQuery.section1} />
      </div>
      <LocationSection3 data={locationData.sectionsQuery.section2} />
      <LocationSection4 data={locationData.sectionsQuery.section3} />
    </LayoutInner>
  )
}

export default LocationPage

export const query = graphql`
  query Location {
    locationQuery: locationPageJson {
      meta {
        title
        shareImage
        keywords
        color
      }
      heroQuery: hero {
        title
        stripes
      }
      sectionsQuery: sections {
        section1 {
          name
          anchor
          content
          content1
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        section2 {
          name
          anchor
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        section3 {
          name
          anchor
          title
          content
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image3 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
