import React from "react";
import PropTypes from "prop-types";
import Section from "../Section";
import Image from "../Image";

import "./Section2.scss";

const LocationSection2 = ({ data }) => {
  return (
    <Section className="two-l jsTwoL">
      <div className="two-l">
        <div className="div1">
          <div
            className="two-l__content jsTwoLContent"
            dangerouslySetInnerHTML={{
              __html: data.content1,
            }}
          />
        </div>
        <div className="div2">
          <Image
            className="jsTwoLImg1"
            data={data.image1}
          />
        </div>
        <div className="div3">
          <Image
            className="jsTwoLImg2"
            data={data.image2}
          />
        </div>
      </div>
    </Section>
  );
};

LocationSection2.propTypes = {
  data: PropTypes.object,
};

export default LocationSection2;
