import React from "react";
import PropTypes from "prop-types";
import Section from "../Section";
import Image from "../Image";

import "./Section4.scss";

const LocationSection4 = ({ data }) => {
  return (
    <Section className="four-l jsFourL" anchor={data.anchor}>
      <div className="four-l">
        <div className="div1">
          <Image
            className="jsFourLImg1"
            data={data.image1}
          />
        </div>
        <div className="div2">
          <Image
            className="jsFourLImg2"
            data={data.image2}
          />
        </div>
        <div className="div3">
          <Image
            className="jsFourLImg3"
            data={data.image3}
          />
        </div>
        <div className="div4">
          <div className="four-l__content jsFourLRight">
            <h2
              className="title jsFourLTitle"
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

LocationSection4.propTypes = {
  data: PropTypes.object,
};

export default LocationSection4;
